export const GET_CHAT_LIST_REQUEST = 'GET_CHAT_LIST_REQUEST';
export const GET_CHAT_LIST_SUCCESS = 'GET_CHAT_LIST_SUCCESS';
export const GET_CHAT_LIST_FAILURE = 'GET_CHAT_LIST_FAILURE';

export const GET_CHAT_BY_ID_REQUEST = 'GET_CHAT_BY_ID_REQUEST';
export const GET_CHAT_BY_ID_SUCCESS = 'GET_CHAT_BY_ID_SUCCESS';
export const GET_CHAT_BY_ID_SUCCESS_NEW_CHAT = 'GET_CHAT_BY_ID_SUCCESS_NEW_CHAT';
export const GET_CHAT_BY_ID_FAILURE = 'GET_CHAT_BY_ID_FAILURE';

export const MARK_MESSAGE_READ_REQUEST = 'MARK_MESSAGE_READ_REQUEST';
export const MARK_MESSAGE_READ_SUCCESS = 'MARK_MESSAGE_READ_SUCCESS';
export const MARK_MESSAGE_READ_FAILURE = 'MARK_MESSAGE_READ_FAILURE';

export const UPLOAD_IMAGE_REQUEST = 'UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE';

export const NEW_MESSAGE_SENT_FROM_SOCKET = 'NEW_MESSAGE_SENT_FROM_SOCKET';
export const NEW_MESSAGE_READ_FROM_SOCKET = 'NEW_MESSAGE_READ_FROM_SOCKET';
export const UPDATE_OR_APPEND_CHAT_INFO = 'UPDATE_OR_APPEND_CHAT_INFO';

export const CHAT_SEARCH_REQUEST = 'CHAT_SEARCH_REQUEST';
export const CHAT_SEARCH_SUCCESS = 'CHAT_SEARCH_SUCCESS';
export const CHAT_SEARCH_FAILURE = 'CHAT_SEARCH_FAILURE';

export const CLEAR_CHAT_SEARCH = 'CLEAR_CHAT_SEARCH';

export const SELECT_CHAT_ITEM = 'SELECT_CHAT_ITEM';

export const GET_LINK_PREVIEW_REQUEST = 'GET_LINK_PREVIEW_REQUEST';
export const GET_LINK_PREVIEW_SUCCESS = 'GET_LINK_PREVIEW_SUCCESS';
export const GET_LINK_PREVIEW_FAILURE = 'GET_LINK_PREVIEW_FAILURE';

export const GET_CHAT_OBJ_BY_PROFILE_ID_REQUEST = 'GET_CHAT_OBJ_BY_PROFILE_ID_REQUEST';
export const GET_CHAT_OBJ_BY_PROFILE_ID_SUCCESS = 'GET_CHAT_OBJ_BY_PROFILE_ID_SUCCESS';
export const GET_NEW_CHAT_OBJ_BY_PROFILE_ID_SUCCESS = 'GET_NEW_CHAT_OBJ_BY_PROFILE_ID_SUCCESS';
export const GET_CHAT_OBJ_BY_PROFILE_ID_FAILURE = 'GET_CHAT_OBJ_BY_PROFILE_ID_FAILURE';

export const GET_FOLLOWERS_REQUEST = 'GET_FOLLOWERS_REQUEST';
export const GET_FOLLOWERS_SUCCESS = 'GET_FOLLOWERS_SUCCESS';
export const GET_FOLLOWERS_FAILURE = 'GET_FOLLOWERS_FAILURE';
export const RESET_FOLLOWERS = 'RESET_FOLLOWERS';

export const SEARCH_FOLLOWERS_REQUEST = 'SEARCH_FOLLOWERS_REQUEST';
export const SEARCH_FOLLOWERS_SUCCESS = 'SEARCH_FOLLOWERS_SUCCESS';
export const SEARCH_FOLLOWERS_FAILURE = 'SEARCH_FOLLOWERS_FAILURE';
export const RESET_SEARCH_FOLLOWERS = 'RESET_SEARCH_FOLLOWERS';

export const CREATE_NEW_GROUP_REQUEST = 'CREATE_NEW_GROUP_REQUEST';
export const CREATE_NEW_GROUP_SUCCESS = 'CREATE_NEW_GROUP_SUCCESS';
export const CREATE_NEW_GROUP_FAILURE = 'CREATE_NEW_GROUP_FAILURE';

export const CREATE_NEW_CHAT_REQUEST = 'CREATE_NEW_CHAT_REQUEST';
export const CREATE_NEW_CHAT_SUCCESS = 'CREATE_NEW_CHAT_SUCCESS';
export const CREATE_NEW_CHAT_FAILURE = 'CREATE_NEW_CHAT_FAILURE';

export const GET_CHAT_INFO_REQUEST = 'GET_CHAT_INFO_REQUEST';
export const GET_CHAT_INFO_SUCCESS = 'GET_CHAT_INFO_SUCCESS';
export const GET_CHAT_INFO_FAILURE = 'GET_CHAT_INFO_FAILURE';

export const GET_CHAT_MEMEBERS_TO_ADD_REQUEST = 'GET_CHAT_MEMEBERS_TO_ADD_REQUEST';
export const GET_CHAT_MEMEBERS_TO_ADD_SUCCESS = 'GET_CHAT_MEMEBERS_TO_ADD_SUCCESS';
export const GET_CHAT_MEMEBERS_TO_ADD_FAILURE = 'GET_CHAT_MEMEBERS_TO_ADD_FAILURE';
export const RESET_CHAT_MEMEBERS_TO_ADD = 'RESET_CHAT_MEMEBERS_TO_ADD';

export const GET_CHAT_MEMEBERS_TO_ADD_SEARCH_REQUEST = 'GET_CHAT_MEMEBERS_TO_ADD_SEARCH_REQUEST';
export const GET_CHAT_MEMEBERS_TO_ADD_SEARCH_SUCCESS = 'GET_CHAT_MEMEBERS_TO_ADD_SEARCH_SUCCESS';
export const GET_CHAT_MEMEBERS_TO_ADD_SEARCH_FAILURE = 'GET_CHAT_MEMEBERS_TO_ADD_SEARCH_FAILURE';
export const RESET_CHAT_MEMEBERS_TO_ADD_SEARCH = 'RESET_CHAT_MEMEBERS_TO_ADD_SEARCH';

export const GET_CHAT_MEMEBERS_REQUEST = 'GET_CHAT_MEMEBERS_REQUEST';
export const GET_CHAT_MEMEBERS_SUCCESS = 'GET_CHAT_MEMEBERS_SUCCESS';
export const GET_CHAT_MEMEBERS_FAILURE = 'GET_CHAT_MEMEBERS_FAILURE';

export const ADD_MEMBER_FROM_CHAT_REQUEST = 'ADD_MEMBER_FROM_CHAT_REQUEST';
export const ADD_MEMBER_FROM_CHAT_SUCCESS = 'ADD_MEMBER_FROM_CHAT_SUCCESS';
export const ADD_MEMBER_FROM_CHAT_FAILURE = 'ADD_MEMBER_FROM_CHAT_FAILURE';

export const DELETE_MEMBER_FROM_CHAT_REQUEST = 'DELETE_MEMBER_FROM_CHAT_REQUEST';
export const DELETE_MEMBER_FROM_CHAT_SUCCESS = 'DELETE_MEMBER_FROM_CHAT_SUCCESS';
export const DELETE_MEMBER_FROM_CHAT_FAILURE = 'DELETE_MEMBER_FROM_CHAT_FAILURE';

export const CLEAR_CHAT_REQUEST = 'CLEAR_CHAT_REQUEST';
export const CLEAR_CHAT_SUCCESS = 'CLEAR_CHAT_SUCCESS';
export const CLEAR_CHAT_FAILURE = 'CLEAR_CHAT_FAILURE';


export const ADD_ADMIN_IN_CHAT_GROUP_REQUEST = 'ADD_ADMIN_IN_CHAT_GROUP_REQUEST';
export const ADD_ADMIN_IN_CHAT_GROUP_SUCCESS = 'ADD_ADMIN_IN_CHAT_GROUP_SUCCESS';
export const ADD_ADMIN_IN_CHAT_GROUP_FAILURE = 'ADD_ADMIN_IN_CHAT_GROUP_FAILURE';

export const REMOVE_AS_ADMIN_FROM_CHAT_GROUP_REQUEST = 'REMOVE_AS_ADMIN_FROM_CHAT_GROUP_REQUEST';
export const REMOVE_AS_ADMIN_FROM_CHAT_GROUP_SUCCESS = 'REMOVE_AS_ADMIN_FROM_CHAT_GROUP_SUCCESS';
export const REMOVE_AS_ADMIN_FROM_CHAT_GROUP_FAILURE = 'REMOVE_AS_ADMIN_FROM_CHAT_GROUP_FAILURE';

export const TOGGLE_CHAT_INFO_MODAL = 'TOGGLE_CHAT_INFO_MODAL';
export const TOGGLE_ADD_PARTICIPANTS_MODAL = 'TOGGLE_ADD_PARTICIPANTS_MODAL';

export const NEW_CHAT_MESSAGE_SUCCESS = 'NEW_CHAT_MESSAGE_SUCCESS';

export const UPDATE_GROUP_INFO_REQUEST = 'UPDATE_GROUP_INFO_REQUEST';
export const UPDATE_GROUP_INFO_SUCCESS = 'UPDATE_GROUP_INFO_SUCCESS';
export const UPDATE_GROUP_INFO_FAILURE = 'UPDATE_GROUP_INFO_FAILURE';

export const UPDATE_AND_SHOW_NEW_MESSAGE_COUNT = 'UPDATE_AND_SHOW_NEW_MESSAGE_COUNT';
export const RESET_AND_HIDE_NEW_MESSAGE_COUNT = 'RESET_AND_HIDE_NEW_MESSAGE_COUNT';

export const CANCEL_REQUEST = 'CANCEL_REQUEST';

export const RESET_MESSAGE_SEEN_COUNT_REQUEST = 'RESET_MESSAGE_SEEN_COUNT_REQUEST';
export const RESET_MESSAGE_SEEN_COUNT_SUCCESS = 'RESET_MESSAGE_SEEN_COUNT_SUCCESS';
export const RESET_MESSAGE_SEEN_COUNT_FAILURE = 'RESET_MESSAGE_SEEN_COUNT_FAILURE';

export const RESET_CHAT = 'RESET_CHAT';
export const RESET_CURRENT_CHAT = 'RESET_CURRENT_CHAT';

export const SEARCH_GROUP_FOLLOWERS_REQUEST = 'SEARCH_GROUP_FOLLOWERS_REQUEST';
export const SEARCH_GROUP_FOLLOWERS_SUCCESS = 'SEARCH_GROUP_FOLLOWERS_SUCCESS';
export const SEARCH_GROUP_FOLLOWERS_FAILURE = 'SEARCH_GROUP_FOLLOWERS_FAILURE';
export const RESET_SEARCH_GROUP_FOLLOWERS = 'RESET_SEARCH_GROUP_FOLLOWERS';

export const UPDATE_CHAT_LIST_FILTER_SUCCESS = "UPDATE_CHAT_LIST_FILTER_SUCCESS";
export const UPDATE_CHAT_LIST_FILTER_REQUEST = "UPDATE_CHAT_LIST_FILTER_REQUEST";


export const DELETE_CHAT_OR_GROUP_REQUEST = 'DELETE_CHAT_OR_GROUP_REQUEST';
export const DELETE_CHAT_OR_GROUP_SUCCESS = 'DELETE_CHAT_OR_GROUP_SUCCESS';
export const DELETE_CHAT_OR_GROUP_FAILURE = 'DELETE_CHAT_OR_GROUP_FAILURE';

export const TOGGLE_REPLY_MESSAGE = 'TOGGLE_REPLY_MESSAGE';